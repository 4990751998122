import InputAddressSearch from '~/components/input/InputAddress/InputAddressSearch';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { useLocation } from 'react-router-dom';
import { useTenantLocation } from '~/tenants/common/TenantContextProvider';

export default function ZodFieldAddress(props: { name: string }) {
  const [latName, lat, setLat] = useZodFormFieldSingle(`${props.name}.lat`);
  const [longName, long, setLong] = useZodFormFieldSingle(`${props.name}.long`);
  const [line1Name, line1, setLine1] = useZodFormFieldSingle(`${props.name}.line1`);
  const [line2Name, line2, setLine2] = useZodFormFieldSingle(`${props.name}.line2`);
  const [stateName, state, setState] = useZodFormFieldSingle(`${props.name}.state`);
  const [cityName, city, setCity] = useZodFormFieldSingle(`${props.name}.city`);
  const [zipName, zip, setZip] = useZodFormFieldSingle(`${props.name}.zip`);
  const [distanceName, distance, setDistance] = useZodFormFieldSingle(`${props.name}.distance`);

  let content = (
    <InputAddressSearch
      onChange={(value) => {
        if (value) {
          setLine1(value.line1);
          setCity(value.city);
          setState(value.state);
          setZip(value.zip);
          setLat(value.lat.toString());
          setLong(value.long.toString());
          setDistance(value.distance?.toString() ?? '0');
        } else {
          setLine1('');
          setCity('');
          setState('');
          setZip('');
          setLat('');
          setLong('');
          setDistance('');
        }
      }}
    />
  );

  if (long) {
    content = <div className="pt-2">{line1}</div>;
  }

  return (
    <>
      <input type="hidden" name={latName} value={lat ?? ''} />
      <input type="hidden" name={longName} value={long ?? ''} />
      <input type="hidden" name={line1Name} value={line1 ?? ''} />
      {line2 && <input type="hidden" name={line2Name} value={line2} />}
      <input type="hidden" name={stateName} value={state ?? ''} />
      <input type="hidden" name={cityName} value={city ?? ''} />
      <input type="hidden" name={zipName} value={zip ?? ''} />
      {distanceName && <input type="hidden" name={distanceName} value={distance ?? '0'} />}

      {content}
    </>
  );
}
